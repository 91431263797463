/* eslint-disable no-unused-vars */
import { TIntegrationFieldMetadata, IntegrationServiceEnum } from "./integrations";
import { User } from "./user";

export type TSalesforceReportName = {
  Id: string;
  Name: string;
};

export type TSalesforceReportSelect = {
  reportName: string;
  reportId: string;
  columns?: string[];
};

export interface TNotification {
  createdAt: string;
  updatedAt: string;
  id: number;
  message: string;
  processed: boolean;
  sourceType: NotificationSourceTypesEnum;
  sourceValue: NotificationStatusTypesEnum;
  type: string;
  __typename: string;
}

export interface TGif {
  id?: string;
  size?: string;
  bitlyGifUrl?: string;
  giphyId?: string;
  includeRecommended?: boolean;
  organizationId?: number;
  tag?: string;
  tags?: string[];
}

export interface IPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ISearchGif {
  nodes: TGif[];
  totalCount: number;
  pageInfo: IPageInfo;
}

export interface TSlackButton {
  type: string;
  name: string;
  style?: string;
  options?: any;
}

export interface ISalesforceUserRole {
  id: number;
  name: string;
  settings: { appHome?: DisplayableAppHomeSettings };
}

export interface DisplayableAppHomeSettings {
  defaultTab: string;
  displayFields: {
    account: string[];
    opportunity: string[];
  };
  editFields: {
    account: string[];
    opportunity: string[];
  };
  tabs: string[];
  type: string;
}

// Enum unused-vars detection doesn't work quite right
export enum SobjectType {
  Account = "account",
  Opportunity = "opportunity",
}

type TBaseTZEntity = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

export type TUserPool = TBaseTZEntity & {
  id: number;
  name: string;
  users?: User[];
};

export enum NotificationStatusTypesEnum {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export enum NotificationSourceTypesEnum {
  DEAL_ASSIST_WIZARD = "deal_assist_wizard",
  MANUAL_NOTIFICATION = "manual_notification",
}

export const slackFooterDividerTypeValues = ["both", "bottom", "none", "top"] as const;

export type TSlackFooterDividerType = typeof slackFooterDividerTypeValues[number];

export const noteConfigSyncToValues = ["SalesforceContentNote", "SalesforceNote", "SalesforceTask"] as const;
export type TNoteConfigSyncTo = typeof noteConfigSyncToValues[number];

export const meetingActionItemSyncToValues = ["SalesforceTask"] as const;
export type TMeetingActionItemSyncTo = typeof meetingActionItemSyncToValues[number];

export const notificationEmailPreferenceValues = ["gmail", "outlook"] as const;
export type TNotificationEmailPreference = typeof notificationEmailPreferenceValues[number];

export interface TFooterOptions {
  allowAcknowledgeSLA: boolean;
  dividerType: TSlackFooterDividerType;
  showCreator: boolean;
  showExecutionId: boolean;
  showLastSent: boolean;
  showLiveMode: boolean;
  showSLAMessage: boolean;
  showSourceLink: boolean;
}

export type TIntegrationsSettings = {
  [IntegrationServiceEnum.ZOOM]: TZoomSettings;
};

export type TZoomSettings = {
  includePasscodeInShareUrl: {
    enabled: boolean; // Include passcode in call url we post in all of the organization's summaries.
    limitToPrivateChannelsAndDirectMessages: boolean; // only add the passcode if it's being sent to a private channel or direct message,
    includeInSalesforceObjects: boolean; // Include passcode in call url while using it in a salesforce object.
  };
};

export interface TWizardSelectionField {
  primaryObject?: string;
  fieldMetadata: TIntegrationFieldMetadata;
  label: string;
  binding: string;
  slackMentionBinding?: string;
  useSlackMentionBinding?: boolean;
  supportsChanges?: boolean;
  salesforceFlowBinding?: string;
  salesforceFlowBindings?: string[];
  selectAsSlackUser?: boolean;
  additionalObject?: string;
  service?: IntegrationServiceEnum;
  outboundMessageFieldName?: string; //this can be an additional object or selected field. Example: Opportunity.Account.Name -> AccountId or Opportunity.Name -> Name
  preloader?: string;
  path?: string;
  description?: string;
  id?: string;
  nonOptionalChangedBinding?: string;
}

export interface IAuthProfile {
  email: string;
  name: string;
  picture: string;
  organizationName: string;
  id?: number;
  role?: string;
}

export enum SalesforceObjectName {
  Account = "Account",
  Lead = "Lead",
  Opportunity = "Opportunity",
}

export enum TeamsFilterEnum {
  SALESFORCE_ROLE = "SALESFORCE_ROLE",
  ROLE = "ROLE",
  INTEGRATION = "INTEGRATION",
  AI_LICENSE = "AI_LICENSE",
  AI_LICENSE_SETTINGS = "AI_LICENSE_SETTINGS",
}

export enum RecipeFilterEnum {
  TAG = "TAG",
  PRODUCT = "PRODUCT",
  VALUE_PROPOSITION = "VALUE_PROPOSITION",
  USER_PERSONA = "USER_PERSONA",
  INTEGRATION = "INTEGRATION",
}

export enum AutopilotExtractionFilterEnum {
  OBJECT = "OBJECT",
  STATUS = "STATUS",
}

export enum ExecutionPipelineFilterEnum {
  SEARCH_ID = "SEARCH_ID",
  STATUS = "STATUS",
  WORKFLOW = "WORKFLOW",
}

export enum WorkflowsFilterEnum {
  SEARCH = "SEARCH",
  TRIGGER = "TRIGGER",
  FLOW_OBJECT = "FLOW_OBJECT",
  STATUS = "STATUS",
  CREATED_BY = "CREATED_BY",
  CATEGORY = "CATEGORY",
}

export enum MeetingsFilterEnum {
  SEARCH = "SEARCH",
  SOURCE = "SOURCE",
}

export type TFilterEnum =
  | TeamsFilterEnum
  | RecipeFilterEnum
  | AutopilotExtractionFilterEnum
  | ExecutionPipelineFilterEnum
  | WorkflowsFilterEnum;

export type TPreLoader = {
  name: string;
  type: PreLoaderTypeEnum;
  condition?: {
    _exists: Record<string, boolean>;
  };
  args: {
    sourceType?: PreLoaderSourceTypeEnum;
    sourceValue?: string;
    objectName?: string;
    fieldName?: string;
  };
};

export enum PreLoaderSourceTypeEnum {
  SALESFORCE_ID = "SALESFORCE_ID",
  EMAIL = "EMAIL",
}

export enum PreLoaderTypeEnum {
  FindUser = "FindUser",
  SalesforceFieldMetadataRetriever = "SalesforceFieldMetadataRetriever",
}

export enum OrganizationPlanEnum {
  free = "free",
  subscription = "subscription",
}

export const planNames: Record<OrganizationPlanEnum, string> = {
  [OrganizationPlanEnum.free]: "Free",
  [OrganizationPlanEnum.subscription]: "Subscription",
};
