export const wizards = {
  state: () => ({
    actionStatus: false,
    salesforceLookups: [],
    selectedFields: [],
    workflow: null,
    newBox: null,
    editorForm: null,
  }),

  mutations: {
    setActionStatus(state, status) {
      state.actionStatus = status;
    },
    setWorkflow(state, data) {
      state.workflow = data;
    },
    setLookupField(state, lookup) {
      state.salesforceLookups.push(lookup);
    },
    setColumn(state, { index, column }) {
      state.salesforceLookups[index].step.args.columns.push(column);
    },
    setField(state, field) {
      state.selectedFields.push(field);
    },
    clearFields(state) {
      state.salesforceLookups = [];
      state.selectedFields = [];
    },
    setFields(state, { selectedFields, lookupFields }) {
      state.salesforceLookups = lookupFields;
      state.selectedFields = selectedFields;
    },
    deleteField(state, field) {
      state.selectedFields = state.selectedFields.filter((item) => item.id !== field.id);

      if (!field.stepName) return;
      const sfLookups = [...state.salesforceLookups];
      const lookupIndex = sfLookups.findIndex((object) => object.stepName === field.stepName);
      const columns = sfLookups[lookupIndex].step.args.columns.filter((column) => column !== field.columnType);
      const isUsedElseWhere = state.selectedFields.some(
        (item) => item.columnType === field.columnType && item.stepName === field.stepName
      );
      //when to delete findFirst object
      if (columns.length === 0 && !isUsedElseWhere) {
        state.salesforceLookups = sfLookups.filter((object) => object.stepName !== field.stepName);
        return;
      }

      if (columns.length && !isUsedElseWhere) {
        state.salesforceLookups[lookupIndex].step.args.columns = columns;
      }
    },
    setBox(state, value) {
      state.newBox = value;
    },
    setEditorForm(state, value) {
      state.editorForm = value;
    },
  },

  actions: {
    setActionStatus({ commit }, status) {
      commit("setActionStatus", status);
    },
    setWorkflow({ commit }, data) {
      commit("setWorkflow", data);
    },
    setLookupField({ commit }, lookup) {
      commit("setLookupField", lookup);
    },
    setColumn({ commit }, { index, column }) {
      commit("setColumn", { index, column });
    },
    setField({ commit, dispatch }, selected) {
      if (selected.findFirst) {
        dispatch("setLookupField", selected.findFirst);
      }

      if (selected.filterColumn) {
        dispatch("setColumn", selected.filterColumn);
      }
      commit("setField", selected.field);
    },
    setLookupFields({ commit }, { selectedFields, lookupFields }) {
      commit("setFields", { selectedFields, lookupFields });
    },
    deleteField({ commit }, field) {
      commit("deleteField", field);
    },
    clearFields({ commit }) {
      commit("clearFields");
    },
    setBox({ commit }, boxContent) {
      commit("setBox", boxContent);
    },
    setEditorForm({ commit }, editorForm) {
      commit("setEditorForm", editorForm);
    },
  },
};
