/* eslint-disable no-unused-vars */ // TODO: no-unused-vars doesn't understand JS. need @typescript-eslint

export enum AuthIntegrationEnum {
  ASANA = "ASANA",
  CHORUS = "CHORUS",
  CLEARBIT = "CLEARBIT",
  CLARI = "CLARI",
  DOCUSIGN = "DOCUSIGN",
  GAINSIGHT = "GAINSIGHT",
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_DOCS = "GOOGLE_DOCS",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  GONG = "GONG",
  JIRA = "JIRA",
  LINEAR = "LINEAR",
  MIXMAX = "MIXMAX",
  NOTION = "NOTION",
  OUTREACH = "OUTREACH",
  SALESFORCE = "SALESFORCE",
  SALESLOFT = "SALESLOFT",
  SENDGRID = "SENDGRID",
  SLACK = "SLACK",
  STRIPE = "STRIPE",
  TRELLO = "TRELLO",
  WINGMAN = "WINGMAN",
  ZENDESK = "ZENDESK",
  ZOOM = "ZOOM",
}

export enum IntegrationAuthTypeEnum {
  APIKEY = "ApiKey",
  DISABLED = "Disabled",
  OAUTH = "OAuth",
}

export enum IntegrationServiceEnum {
  GONG = "GONG",
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  MOMENTUM = "MOMENTUM",
  SALESFORCE = "SALESFORCE",
  STRIPE = "STRIPE",
  SLACK = "SLACK",
  ZOOM = "ZOOM",
}

export enum ExcludedIntegrationServiceEnum {
  CHORUS = "CHORUS",
  CLARI = "CLARI",
  CLEARBIT = "CLEARBIT",
  DOCUSIGN = "DOCUSIGN",
  GAINSIGHT = "GAINSIGHT",
  JIRA = "JIRA",
  LINEAR = "LINEAR",
  MIXMAX = "MIXMAX",
  NOTION = "NOTION",
  SENDGRID = "SENDGRID",
  TRELLO = "TRELLO",
  WINGMAN = "WINGMAN",
}

export type TIntegrationsObjectsMetadata = Record<
  IntegrationServiceEnum,
  Record<TIntegrationObjectName, TIntegrationObjectMetadata>
>;

export interface TIntegration {
  endpoint: string;
  service: IntegrationServiceEnum;
  __typename: string;
}

// These types are derived from and compatiable with Salesforce types

// Object Metadata

export type TIntegrationObjectName = string & { readonly brand?: unique symbol };
export type TIntegrationObjectLabel = string & {
  readonly brand?: unique symbol;
};
export type TIntegrationObjectLabelPlural = string & {
  readonly brand?: unique symbol;
};
export type TIntegrationObjectFunctionalityFlags = {
  createable: boolean;
  deletable: boolean;
  retrieveable: boolean;
  searchable: boolean;
  // Indicates whether the object supports Apex triggers.
  // This might be useful to understand whether we can support with OneGraph subscriptions.
  // Drop this column if deemed unuseful.
  triggerable: boolean;
  undeletable: boolean;
  updateable: boolean;
};
export type TIntegrationObjectMetadata = {
  custom?: boolean;
  fields: TIntegrationFieldMetadata[];
  functionality?: TIntegrationObjectFunctionalityFlags;
  label: TIntegrationObjectLabel;
  labelPlural: TIntegrationObjectLabelPlural;
  name: TIntegrationObjectName;
};

// Field Metadata

export type TIntegrationFieldName = string & { readonly brand?: unique symbol };
export type TIntegrationFieldLabel = string & {
  readonly brand?: unique symbol;
};
export type TPicklistLabel = string & {
  readonly brand?: unique symbol;
};
export type TPicklistEntry = {
  active?: boolean;
  defaultValue?: boolean;
  label: TPicklistLabel | null;
  // TODO: support Dependent Picklists
  // https://developer.salesforce.com/docs/atlas.en-us.api.meta/api/sforce_api_calls_describesobjects_describesobjectresult.htm#aboutPicklists
  validFor?: null;
  value: TIntegrationFieldValue;
};
export type TIntegrationFieldFunctionalityFlags = {
  createable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  nillable?: boolean;
  permissionable?: boolean;
  searchPrefilterable?: boolean;
  sortable?: boolean;
  updateable?: boolean;
};
export type TIntegrationFieldMetadata = {
  array?: boolean; // non-Salesforce field. Indicates field is an array
  binding?: string; // non-Salesforce field. Override the oneGraphified binding for the field name. Doesn't support refs
  caseSensitive?: boolean;
  custom?: boolean;
  defaultValue?: any;
  functionality?: TIntegrationFieldFunctionalityFlags;
  label: TIntegrationFieldLabel;
  maxLength?: number | null;
  name: TIntegrationFieldName;
  nameField?: boolean;
  picklistValues?: TPicklistEntry[];
  readonly?: boolean;
  referenceTo?: TIntegrationObjectName[];
  relationshipName?: string;
  // TODO: make mandatory
  type?: IntegrationFieldTypeEnum;
  __typename?: string;
  hardCoded?: boolean;
  omitFromQuery?: boolean;
  limitSelectableToOnlyObjectType?: boolean;
};

export enum IntegrationFieldTypeEnum {
  // Address type
  address = "address",
  // Primary key field for the object. For information on IDs, see Field Types.
  id = "id",
  // Values can be any of these types: string, picklist, boolean, int, double, percent, ID, date, dateTime, url, or email.
  anyType = "anyType",
  // Base64-encoded arbitrary binary data (of type base64Binary). Used for Attachment, Document, and Scontrol objects.
  base64 = "base64",
  // Boolean (true / false) values.
  boolean = "boolean",
  // Comboboxes, which provide a set of enumerated values and allow the user to specify a value not in the list.
  combobox = "combobox",
  // Currency values.
  currency = "currency",
  // Date values.
  date = "date",
  // Date and time values.
  datetime = "datetime",
  // Double values.
  double = "double",
  // Email addresses.
  email = "email",
  // Integer values.
  int = "int",
  // Geolocation values, including latitude and longitude, for custom geolocation fields on custom objects.
  location = "location",
  // Multi-select picklists, which provide a set of enumerated values from which multiple values can be selected.
  multipicklist = "multipicklist",
  // Percentage values.
  percent = "percent",
  // Phone numbers. Values can include alphabetic characters. Client applications are responsible for phone number formatting.
  phone = "phone",
  // Single-select picklists, which provide a set of enumerated values from which only one value can be selected.
  picklist = "picklist",
  // Cross-references to a different object. Analogous to a foreign key field in SQL.
  reference = "reference",
  //String values.
  string = "string",
  // String that is displayed as a multiline text field.
  textarea = "textarea",
  // URL values. Client applications should commonly display these as hyperlinks.
  // If Field.extraTypeInfo is imageurl, the URL references an image, and can be displayed as an image instead.
  url = "url",
  // Non-Salesforce type. Used to signal, we don't know what this type is, or we don't want to support it yet.
  unsupported = "unsupported",
}

export type TIntegrationFieldValue = any;

export interface IIntegration {
  service: string;
}

export interface IntegrationUser {
  email: string;
  status?: string;
  updatedAt?: string;
}

export interface IActiveIntegration extends IIntegration {
  status?: string;
  updatedAt?: string;
  user?: IntegrationUser;
  useForOrganization?: boolean;
}

export interface IOrgIntegration extends IIntegration {
  serviceStatus?: string;
  users: IntegrationUser[];
}

export interface ISlackWorkspace extends IActiveIntegration {
  installation: Object;
  teamID: Object;
}

export interface IApiKeyIntegration {
  service: AuthIntegrationEnum;
  connected: boolean;
  keys: string[];
}

export enum ServiceStatusEnum {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  AUTHENTICATED = "AUTHENTICATED",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  UNUSED = "UNUSED",
}

export const authIntegrationNames: Record<AuthIntegrationEnum, string> = {
  [AuthIntegrationEnum.ASANA]: "Asana",
  [AuthIntegrationEnum.CHORUS]: "Chorus",
  [AuthIntegrationEnum.CLEARBIT]: "Clearbit",
  [AuthIntegrationEnum.CLARI]: "Clari",
  [AuthIntegrationEnum.DOCUSIGN]: "Docusign",
  [AuthIntegrationEnum.GAINSIGHT]: "Gainsight",
  [AuthIntegrationEnum.GOOGLE_CALENDAR]: "Google Calendar",
  [AuthIntegrationEnum.GOOGLE_DOCS]: "Google Docs",
  [AuthIntegrationEnum.GOOGLE_DRIVE]: "Google Drive",
  [AuthIntegrationEnum.GONG]: "Gong",
  [AuthIntegrationEnum.JIRA]: "Jira",
  [AuthIntegrationEnum.LINEAR]: "Linear",
  [AuthIntegrationEnum.MIXMAX]: "Mixmax",
  [AuthIntegrationEnum.NOTION]: "Notion",
  [AuthIntegrationEnum.OUTREACH]: "Outreach",
  [AuthIntegrationEnum.SALESFORCE]: "Salesforce",
  [AuthIntegrationEnum.SALESLOFT]: "Salesloft",
  [AuthIntegrationEnum.SENDGRID]: "Sendgrid",
  [AuthIntegrationEnum.SLACK]: "Slack",
  [AuthIntegrationEnum.STRIPE]: "Stripe",
  [AuthIntegrationEnum.TRELLO]: "Trello",
  [AuthIntegrationEnum.WINGMAN]: "Clari Copilot",
  [AuthIntegrationEnum.ZENDESK]: "Zendesk",
  [AuthIntegrationEnum.ZOOM]: "Zoom",
};

export const serviceStatusNames: Record<ServiceStatusEnum, string> = {
  [ServiceStatusEnum.CONNECTED]: "Connected",
  [ServiceStatusEnum.DISCONNECTED]: "Not Connected",
  [ServiceStatusEnum.AUTHENTICATED]: "Connected",
  [ServiceStatusEnum.AUTHENTICATION_FAILED]: "Authentication Failed",
  [ServiceStatusEnum.UNUSED]: "Unused",
};
