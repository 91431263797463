import { TMeetingCall } from "../types/meeting";
import { IAutopilot, IDataInsightLogs, IAutopilotExtraction } from "../types/autopilot";
import { ISalesforceFieldsForObject } from "../types/salesforce";

interface State {
  calls: TMeetingCall[];
  salesforceFields: ISalesforceFieldsForObject;
  autopilots: IAutopilot[];
  recentChanges: IDataInsightLogs[];
  salesforceObject: {
    [key: string]: {
      id: string;
      name: string;
      sobjectMetadata: {
        uiDetailUrl: string;
      };
    };
  };
  extraction: IAutopilotExtraction;
  extractions: IAutopilotExtraction[];
}
export const autopilot = {
  state: () =>
    <State>{
      calls: null,
      salesforceFields: null,
      autopilots: null,
      recentChanges: null,
      salesforceObject: null,
      extraction: null,
    },

  mutations: {
    setCalls(state, data): void {
      state.calls = data;
    },
    setSalesforceFields(state, data): void {
      state.salesforceFields = data;
    },
    setAutopilots(state, data): void {
      state.autopilots = data;
    },
    setRecentChanges(state, data): void {
      state.recentChanges = data;
    },
    setSalesforceObject(state, data): void {
      state.salesforceObject = data;
    },
    setExtraction(state, data): void {
      state.extraction = data;
    },
    setExtractions(state, data): void {
      state.extractions = data;
    },
  },

  actions: {
    setCalls({ commit }, data): void {
      commit("setCalls", data);
    },
    setSalesforceFields({ commit }, data): void {
      commit("setSalesforceFields", data);
    },
    setAutopilots({ commit }, data): void {
      commit("setAutopilots", data);
    },
    setRecentChanges({ commit }, data): void {
      commit("setRecentChanges", data);
    },
    setSalesforceObject({ commit }, data): void {
      commit("setSalesforceObject", data);
    },
    setExtraction({ commit }, data): void {
      commit("setExtraction", data);
    },
    setExtractions({ commit }, data): void {
      commit("setExtractions", data);
    },
  },
};
