import { isSupportConsole } from "./is-support-console";

export function getViteConnection() {
  return _getViteConnection(
    isSupportConsole()
      ? // backwards compatible, so all engineers do not need to add env vars immediately
        import.meta.env.VITE_CONNECTION_SUPPORT || import.meta.env.VITE_CONNECTION
      : import.meta.env.VITE_CONNECTION
  );
}
function _getViteConnection(viteConnection) {
  return viteConnection === "ANY" ? undefined : viteConnection;
}
