/* eslint-disable no-unused-vars */
import { TPreLoader } from "./momentum";

interface Notification {
  strategy: string;
  reminderMinutes: number;
  receiver: {
    type: string;
    slackUserId?: string;
    slackChannelId?: string;
  };
}

export interface ActionButtons {
  type: string;
  name?: string;
  style?: string;
}

export interface GifOptions {
  gifTag?: string;
  giphyId?: string;
  size?: string;
  includeRecommended?: boolean;
}
export interface FooterOptions {
  showCreator?: boolean;
  showSLAMessage?: boolean;
  allowAcknowledgeSLA?: boolean;
  showSourceLink?: boolean;
  dividerType?: string;
  showExecutionId?: boolean;
  showLastSent?: boolean;
  showLiveMode?: boolean;
}
export interface SLASchedule {
  slaMinutes: number;
  notifications: Array<Notification>;
}

export type TScheduleOptions = {
  paths: TSchedulePathOptions[];
  runImmediately?: boolean;
};

export type TSchedulePathOptions = {
  offsetNumber?: number;
  offsetUnit?:
    | "Months After"
    | "Months Before"
    | "Days After"
    | "Days Before"
    | "Hours After"
    | "Hours Before"
    | "Minutes After"
    | "Minutes Before";
  recordField: string;
};

export interface Condition {
  type: string;
  predicate: string;
}

export type TChange = {
  field: string | Record<string, any>;
  value: string;
};

export interface CustomApprover {
  type: string;
  description?: string;
  args: {
    dialogStepName: string;
    allowType: string;
    allowCreateConversation: boolean;
    allowPrivateChannel: boolean;
    allowApproversSlackUserIds: string[];
    autoInviteApprovers: boolean;
    optionalReason: boolean;
    approver: {
      type: string;
      isPrivate: boolean;
      slackChannelId?: string;
      slackUserId?: string;
    };
    autoApprovePredicate?: string;
    threadedMessageBlocks?: any[];
  };
  slaSchedule?: SLASchedule;
  footerOptions?: FooterOptions;
  condition?: Condition;
  preloaders?: TPreLoader[];
  routes: Array<string>;
}

export interface SalesforceObjectUpdater {
  type: string;
  description?: string;
  args: {
    changes: TChange[];
    sobjectType: string;
    sobjectId: string;
  };
  condition?: Condition;
  routes: Array<string>;
}

export interface SlackConversationArchiverOrSlackChannelMemorializer {
  type: string;
  description?: string;
  args: {
    slackChannelId?: string;
    channelId?: string;
  };
  condition?: Condition;
  routes: Array<string>;
}

export interface SalesforceLogger {
  type: string;
  description: string;
  args: {
    comment: string;
    sourceType: string;
    sourceValue: string;
  };
  condition?: Condition;
  routes: string[];
}

export interface SlackDirectMessage {
  type: string;
  description?: string;
  args: {
    pinned: boolean;
    message: string;
    attachments?: any[];
    blocks?: any[];
    receiver: {
      slackUserId?: string;
      slackChannelId?: string;
      isPrivate?: boolean;
    };
    buttons?: Array<ActionButtons>;
    gifOptions?: GifOptions;
    threadedBlocks?: any[];
  };
  preloaders?: TPreLoader[];
  condition?: Condition;
  routes?: string[];
}

export interface SlackAutoInviteUsers {
  type: string;
  description: string;
  args: {
    slackChannelId: string;
    slackUserIds: string[];
  };
  condition?: Condition;
  routes: string[];
}

export interface FindManager {
  args: {
    managerOf: boolean;
    slackUserId: string;
  };
  condition?: Condition;
  routes?: string[];
  type: string;
}

export interface FindFirst {
  args: {
    provider: string;
    resource: string;
    columns: string[];
    filters: FindFirstFilters[];
  };
  condition?: Condition;
  routes?: string[];
  type: string;
}

export interface FindFirstFilters {
  column: string;
  operator: string;
  value: string;
}

export interface Dialog {
  args: {
    title: string;
    headerMessage?: string;
    elements?: any[];
    groupFields?: DialogGroupField[];
  };
  condition?: Condition;
  routes?: string[];
  type: string;
}

export interface DialogGroupField {
  showIn: string;
  value: string;
  format: string;
  key: string;
  groupName: string;
}

export interface Execute {
  type: string;
  args: {
    definitionId: string;
  };
  condition?: Condition;
  description: string;
  routes?: string[];
}

export interface ISlackDirectMessageReceiver {
  slackUserId?: string;
  slackChannelId?: string;
}

export enum TActionTypesEnum {
  CallSummaryNotification = "CallSummaryNotification",
  CallAiSignalFetch = "CallAiSignalFetch",
  ContactAutomation = "ContactAutomation",
  CreateDigestContext = "CreateDigestContext",
  CreateDigestDefinition = "CreateDigestDefinition",
  CreateDigestInstance = "CreateDigestInstance",
  Delay = "Delay",
  Dialog = "Dialog",
  EmailAiSignalFetch = "EmailAiSignalFetch",
  PostDigestInstanceToSlack = "PostDigestInstanceToSlack",
  SalesforceObjectCreator = "SalesforceObjectCreator",
  SalesforceObjectUpdater = "SalesforceObjectUpdater",
  SalesforceReportCsvToSlackDM = "SalesforceReportCsvToSlackDM",
  SlackAiNotificationPromptFetch = "SlackAiNotificationPromptFetch",
  SlackAutoInviteUsers = "SlackAutoInviteUsers",
  SlackChannelCreator = "SlackChannelCreator",
  SlackConversationArchiver = "SlackConversationArchiver",
  SlackDealRoomCreator = "SlackDealRoomCreator",
  SlackDirectMessage = "SlackDirectMessage",
  SlackMessageSalesforceSOQLScroller = "SlackMessageSalesforceSOQLScroller",
  State = "State",
  SuggestEmail = "SuggestEmail",
}
