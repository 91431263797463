import { createAuth0, Auth0VueClient, Auth0Plugin } from "@auth0/auth0-vue";
import { getViteConnection } from "./utils/get-vite-connection";
import { isSupportConsole } from "./utils/is-support-console";
const env: Record<string, string> = import.meta.env;

export const auth0: Auth0Plugin = createAuth0(
  {
    domain: env.VITE_AUTH0_DOMAIN,
    clientId: isSupportConsole()
      ? // backwards compatible, so all engineers do not need to add env vars immediately
        env.VITE_AUTH0_CLIENT_ID_SUPPORT || env.VITE_AUTH0_CLIENT_ID
      : env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      connection: getViteConnection(),
      audience: `https://${env.VITE_AUTH0_DEFAULT_TENANT_DOMAIN}/userinfo`,
      redirect_uri: window.location.origin + "/auth/callback",
    },
  },
  {
    skipRedirectCallback: window.location.href.includes("error"),
  }
);

export async function loginAsUser(
  organizationId: number,
  auth: Auth0VueClient,
  userId: number,
  userRole?: string
): Promise<void> {
  await auth.loginWithRedirect({
    authorizationParams: {
      prompt: "login",
      momentumOrganizationId: organizationId,
      momentumUserId: userId,
      momentumUserRole: userRole,
    },
  });
}

export function getAuthToken(auth0: Auth0VueClient): string {
  const token = auth0.idTokenClaims.value.__raw;
  return token;
}
