import { v4 as uuidv4 } from "uuid";
import { NotificationSourceTypesEnum, NotificationStatusTypesEnum } from "../types/momentum";
const notification = {
  createdAt: new Date().toJSON(),
  updatedAt: null,
  __typename: "push_notification",
};
export const notifications = {
  state: () => ({
    notifications: [],
  }),

  getters: {
    getNotifications(state) {
      return state.notifications.filter((item) => {
        if (!item.processed) {
          return item;
        }
      });
    },
  },

  mutations: {
    setNotifications(state, message) {
      state.notifications.push(message);
    },
    removeNotification(state, id) {
      state.notifications = state.notifications.filter((item) => item.id !== id);
    },
  },

  actions: {
    setSuccessMessage({ commit }, message) {
      const successMessage = {
        ...notification,
        type: NotificationStatusTypesEnum.INFO,
        sourceType: NotificationSourceTypesEnum.MANUAL_NOTIFICATION,
        id: uuidv4(),
        message,
      };

      commit("setNotifications", successMessage);
    },
    setErrorMessage({ commit }, message) {
      const errorMessage = {
        ...notification,
        sourceType: NotificationSourceTypesEnum.MANUAL_NOTIFICATION,
        type: NotificationStatusTypesEnum.ERROR,
        id: uuidv4(),
        message,
      };

      commit("setNotifications", errorMessage);
    },
    setWarningMessage({ commit }, message) {
      const warningMessage = {
        ...notification,
        sourceType: NotificationSourceTypesEnum.MANUAL_NOTIFICATION,
        type: NotificationStatusTypesEnum.WARNING,
        id: uuidv4(),
        message,
      };

      commit("setNotifications", warningMessage);
    },
    setDealAssistNotifications({ commit }, data) {
      commit("setNotifications", data);
    },
    removeNotification({ commit }, id) {
      commit("removeNotification", id);
    },
  },
};
