import { capitalize } from "lodash";
import { IntegrationServiceEnum, TIntegrationObjectName } from "../types/integrations";
import { TTriggerMetadata, TTriggerMetadataServiceObject, TTriggerType } from "../types/triggers";

export class TriggerMetadata {
  private static allTriggersMetadata: Record<TTriggerType, TTriggerMetadata>;

  static fetchAllTriggerMetadata(): Promise<void> {
    if (this.allTriggersMetadata) {
      return Promise.resolve();
    }
    // Simulate fetching from the backend, so we catch and async issues early
    return new Promise((resolve) =>
      setTimeout(() => {
        // Eventually this should be served from the BE
        this.allTriggersMetadata = hardCodedTriggersMetadata;
        resolve();
      }, 100)
    );
  }

  // used for tests
  public static overrideAllTriggersMetadata(allTriggersMetadata: Record<TTriggerType, TTriggerMetadata>): void {
    this.allTriggersMetadata = allTriggersMetadata;
  }

  static getTriggerMetadata(triggerName: TTriggerType): TTriggerMetadata {
    return this.allTriggersMetadata[triggerName];
  }

  static isAllTriggersMetadataLoaded(): boolean {
    return Boolean(this.allTriggersMetadata);
  }

  static allLoadedTriggerNames(): TTriggerType[] {
    return Object.keys(this.allTriggersMetadata || {}) as TTriggerType[];
  }

  static isValidTriggerName(triggerName: TTriggerType): boolean {
    return Object.prototype.hasOwnProperty.call(this.allTriggersMetadata, triggerName);
  }

  readonly triggerName: TTriggerType;
  protected _metadata: TTriggerMetadata;

  constructor(triggerName: TTriggerType) {
    if (!TriggerMetadata.isAllTriggersMetadataLoaded()) {
      throw new Error("Trigger metadata is not loaded.");
    }
    if (!TriggerMetadata.isValidTriggerName(triggerName)) {
      throw new Error(`Trigger name "${triggerName}" is invalid. Use isValidTriggerName(triggerName) to check`);
    }
    this._metadata = TriggerMetadata.allTriggersMetadata[triggerName];
    this.triggerName = triggerName;
  }

  public primaryService(): IntegrationServiceEnum {
    return this._metadata.primaryObject.service;
  }

  public primaryObjectName(): TIntegrationObjectName {
    return this._metadata.primaryObject?.objectName ?? null;
  }

  public primaryObjectBindingNameOverride(): string {
    return this._metadata.primaryObject.bindingNameOverride;
  }

  public secondaryObjects(): TTriggerMetadataServiceObject[] {
    return this._metadata.secondaryObjects || [];
  }

  public rootObjects(): TTriggerMetadataServiceObject[] {
    return [this._metadata.primaryObject, ...(this._metadata.secondaryObjects || [])].filter(Boolean);
  }

  public hasChanges(): boolean {
    return this._metadata.changes || false;
  }

  public useRecordBindings(): boolean {
    return this._metadata.recordBindings || false;
  }

  public additionalBindings(): string[] {
    return this._metadata.additionalBindings;
  }

  public getTriggerActionType(): string {
    const splitTrigger: string[] = this.triggerName.split("_");
    return splitTrigger[splitTrigger.length - 1];
  }

  public getFlowTriggerActionType(): string {
    const splitTrigger: string[] = this.triggerName.split("_");
    const getLastWord: string = splitTrigger[splitTrigger.length - 1];
    let actionType: string = "";
    if (getLastWord === "upserted") {
      actionType = "CreateAndUpdate";
    } else {
      actionType = capitalize(getLastWord).slice(0, -1); // remove the 'd' from created, updated to be consistent with the flow action type
    }

    return actionType;
  }
}

const hardCodedTriggersMetadata = {
  account_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Account" },
    secondaryObjects: [{ service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" }],
    changes: false,
  },
  account_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Account" },
    secondaryObjects: [{ service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" }],
    changes: true,
  },
  account_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Account" },
    secondaryObjects: [{ service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" }],
    changes: true,
    additionalBindings: ["upsertType"],
  },
  assist_channel_created: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" },
    changes: false,
  },
  ai_insight_generated: {
    primaryObject: {
      service: IntegrationServiceEnum.MOMENTUM,
      objectName: "Meeting",
    },
    changes: false,
  },
  ai_signal_created: {
    primaryObject: {
      service: IntegrationServiceEnum.MOMENTUM,
      objectName: "Meeting",
    },
    changes: false,
  },
  call_analyzed: {
    primaryObject: {
      service: IntegrationServiceEnum.MOMENTUM,
      objectName: "Call",
    },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Account",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Case",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Event",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Opportunity",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Lead",
      },
    ],
    changes: false,
    additionalBindings: ["salesforceHostUser.department"],
  },
  case_comment_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "CaseComment" },
    changes: false,
  },
  case_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Case" },
    changes: false,
  },
  case_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Case" },
    changes: true,
  },
  case_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Case" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  channel_message_posted: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "ChannelPostedMessage" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
      { service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" },
    ],
    changes: false,
  },
  contact_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Contact" },
    changes: false,
  },
  contact_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Contact" },
    changes: true,
  },
  contact_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Contact" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  cron_scheduler: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "CronScheduler" },
    changes: false,
    additionalBindings: ["objectName"],
  },
  custom_object_created: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "CustomObject" },
    changes: false,
    additionalBindings: ["objectName"],
  },
  custom_object_updated: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "CustomObject" },
    changes: true,
    additionalBindings: ["objectName"],
  },
  custom_object_upserted: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "CustomObject" },
    changes: true,
    additionalBindings: ["objectName", "upsertType"],
  },
  customer_subscription_updated: {
    primaryObject: { service: IntegrationServiceEnum.STRIPE, objectName: "Subscription" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.STRIPE,
        objectName: "Customer",
      },
      {
        service: IntegrationServiceEnum.STRIPE,
        objectName: "PreviousSubscription",
      },
    ],
    changes: false,
    additionalBindings: ["stripeEventId"],
  },
  dealroom_activity: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Activity",
      },
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
    ],
    changes: false,
  },
  dealroom_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
    ],
    changes: false,
  },
  event_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Event" },
    changes: false,
  },
  event_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Event" },
    changes: true,
  },
  event_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Event" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  feed_item_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "FeedItem" },
    changes: false,
    additionalBindings: [
      "feedItem.id",
      "feedItem.type",
      "feedItem.body",
      "feedItem.sobjectMetadata.uiDetailUrl",
      "feedItem.createdBy.id",
      "feedItem.createdBy.username",
      "feedItem.createdBy.name",
      "feedItem.createdBy.email",
      "users",
      "markdown",
    ],
  },
  gong_call_ended: {
    primaryObject: { service: IntegrationServiceEnum.GONG, objectName: "Call", bindingNameOverride: "gongCall" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "AssistChannel",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Account",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Opportunity",
      },
    ],
    changes: false,
  },
  lead_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Lead" },
    changes: false,
  },
  lead_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Lead" },
    changes: true,
  },
  lead_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Lead" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  member_joined_dealroom: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "User",
        bindingNameOverride: "member",
      },
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
    ],
    changes: false,
  },
  menu: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    secondaryObjects: [{ service: IntegrationServiceEnum.MOMENTUM, objectName: "Dealroom" }],
    changes: false,
    additionalBindings: ["shortcutMessage"],
  },
  note_action_item_assigned: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "NoteActionItem" },
    changes: false,
  },
  note_action_item_completed: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "NoteActionItem" },
    changes: false,
  },
  note_action_item_created: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "NoteActionItem" },
    changes: false,
  },
  meeting_note_created: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "MeetingNote" },
    changes: false,
  },
  meeting_note_updated: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "MeetingNote" },
    changes: false,
    additionalBindings: [
      "previousMeetingNote.content",
      "previousMeetingNote.title",
      "previousMeetingNote.salesforceNoteId",
    ],
  },
  opportunity_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    changes: false,
  },
  opportunity_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    changes: true,
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
    ],
  },
  opportunity_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  quote_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Quote" },
    changes: false,
  },
  quote_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Quote" },
    changes: true,
  },
  quote_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Quote" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  reaction_to_message_added: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "Reaction" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
      { service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" },
    ],
    changes: false,
  },
  request_form: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Opportunity" },
    changes: false,
  },
  "salesforce.flow": {
    primaryObject: null,
    secondaryObjects: [],
    changes: false,
    recordBindings: true,
  },
  salesforce_google_calendar_event: {
    primaryObject: {
      service: IntegrationServiceEnum.GOOGLE_CALENDAR,
      objectName: "Event",
      bindingNameOverride: "googleEvent",
    },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.GOOGLE_CALENDAR,
        objectName: "ExpandedEventInfo",
      },
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Opportunity",
      },
    ],
    changes: false,
  },
  salesforce_google_calendar_finished_event: {
    primaryObject: {
      service: IntegrationServiceEnum.GOOGLE_CALENDAR,
      objectName: "Event",
      bindingNameOverride: "googleEvent",
    },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.GOOGLE_CALENDAR,
        objectName: "ExpandedEventInfo",
      },
      {
        service: IntegrationServiceEnum.MOMENTUM,
        objectName: "Dealroom",
      },
      {
        service: IntegrationServiceEnum.SALESFORCE,
        objectName: "Opportunity",
      },
    ],
    changes: false,
  },
  sbqq_quote_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "SBQQ__Quote__c" },
    changes: false,
  },
  sbqq_quote_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "SBQQ__Quote__c" },
    changes: true,
  },
  sbqq_quote_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "SBQQ__Quote__c" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  sla_reminder: {
    primaryObject: {
      service: IntegrationServiceEnum.MOMENTUM,
      objectName: "SlackReceiver",
      bindingNameOverride: "receiver",
    },
    changes: false,
    additionalBindings: ["currentDate", "dueDate", "reminderMinutes"],
  },
  slack_action_event: {
    primaryObject: { service: IntegrationServiceEnum.MOMENTUM, objectName: "SlackAction" },
    changes: false,
  },
  slack_file_upload: {
    primaryObject: {
      service: IntegrationServiceEnum.MOMENTUM,
      objectName: "SlackFileUpload",
      bindingNameOverride: "fileUpload",
    },
    secondaryObjects: [{ service: IntegrationServiceEnum.MOMENTUM, objectName: "AssistChannel" }],
    changes: false,
  },
  stripe_customer_subscription_created: {
    primaryObject: { service: IntegrationServiceEnum.STRIPE, objectName: "Subscription" },
    secondaryObjects: [
      {
        service: IntegrationServiceEnum.STRIPE,
        objectName: "Customer",
      },
    ],
    changes: false,
    additionalBindings: ["stripeEventId"],
  },
  task_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Task" },
    changes: false,
  },
  task_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Task" },
    changes: true,
  },
  task_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "Task" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
  user_created: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "User" },
    changes: false,
  },
  user_updated: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "User" },
    changes: true,
  },
  user_upserted: {
    primaryObject: { service: IntegrationServiceEnum.SALESFORCE, objectName: "User" },
    changes: true,
    additionalBindings: ["upsertType"],
  },
};
