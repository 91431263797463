<template>
  <main class="w-full flex justify-center overflow-y-auto h-full focus:outline-none" tabindex="0">
    <div class="py-4 w-full">
      <div class="px-4 sm:px-6 md:px-8">
        <div v-if="showHeader" class="flex items-center mx-20">
          <NPopover v-if="!store.state.showSideBar" trigger="hover">
            <template #trigger>
              <NButton class="-ml-3" type="secondary" @click="toggleNav">
                <MIcon icon="IconPanelLeftExpand" class="w-6 h-6" />
              </NButton>
            </template>
            <span>Expand Sidebar</span>
          </NPopover>
          <h1 class="text-xl font-medium" v-text="store.state.header" />
        </div>
        <!-- body content -->
        <div class="py-4 mx-20">
          <slot />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { NButton, NPopover } from "naive-ui";
import MIcon from "../../components/reusable-components/MIcon.vue";

export default defineComponent({
  components: {
    NButton,
    NPopover,
    MIcon,
  },
  setup() {
    const store: any = useStore();
    const route: any = useRoute();

    const showHeader: any = computed(() => {
      return !route.fullPath.includes("/meetings");
    });

    function toggleNav(): void {
      store.dispatch("toggleSideBar", !store.state.showSideBar);
    }
    return {
      store,
      showHeader,
      toggleNav,
    };
  },
});
</script>
