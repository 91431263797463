/* eslint-disable no-unused-vars */
import { TTriggerType } from "./triggers";
import { TActionTypesEnum, TScheduleOptions } from "./action-types";
import { TFlowFilter } from "./condition-builder";
import { User } from "./user";

export enum AssistDefinitionCategoryEnum {
  AI_CALL_NOTIFICATION = "ai_call_notification",
  AI_NOTIFICATION = "ai_notification",
  AI_SIGNAL = "ai_signal",
  APPROVAL_ADVANCED = "approval_advanced",
  APPROVAL_CUSTOM = "approval_custom",
  AUTO_CREATE_ROOMS = "auto_create_rooms",
  AUTOPILOT_EXTRACTIONS = "autopilot_extractions",
  CONTACT_AUTOMATION = "contact_automation",
  EXECUTIVE_BRIEF = "executive_brief",
  DEALROOM_AUTO_INVITE = "dealroom_auto_invite",
  DEALROOM_RENAME = "dealroom_rename",
  DEFAULT = "default",
  ONBOARDING = "onboarding",
  OPPORTUNITY_NOTIFICATION = "opportunity_notification",
  RECIPE = "recipe",
  SCHEDULED_NOTIFICATION = "scheduled_notification",
  SCHEDULED_REPORT = "scheduled_report",
  SIMPLE_TASK = "simple_task",
}

export interface TAssistDefinition {
  error?: string;
  id?: number;
  name: string;
  category: AssistDefinitionCategoryEnum;
  createdAt?: Date;
  createdBy?: User;
  description?: string;
  deletedAt?: Date | null;
  enabled?: boolean;
  isInternal?: boolean;
  lastExecutionAt?: Date;
  numberOfExecutions?: number;
  triggerType: TTriggerType;
  updatedAt?: Date;
  wizardMetadata?: Record<string, any>;
  wizard: TAssistDefinitionWizard | TAssistDefinitionMetadataWizard;
}

type TAssistDefinitionMetadataWizard = {
  jsonEditor: TAssistDefinitionWizard;
  template?: Record<string, any>;
  uiSettings?: Record<string, any>;
  version: "V2";
  formSchema?: { variables?: any[]; uiSettings?: Record<string, any> };
};

export type TAssistDefinitionWizard = {
  trigger: TTriggerType;
  condition?: string;
  cron?: string;
  cronTimezone?: string;
  version?: "2";
  salesforceFlow?: {
    objectName: string;
    recordTriggerType: TSalesforceFlowTriggerType;
    scheduleOptions?: TScheduleOptions;
    condition?: TSalesforceFlowCondition;
    outboundMessageFieldNames?: string[];
  };
  conditionAdditionalObjects?: TAdditionalObjects;
  additionalObjects?: TAdditionalObjects;
  steps: Record<string, TStep> | TStep[];
};

export type TSalesforceFlowTriggerType = "Update" | "Create" | "CreateAndUpdate";

type TAdditionalObjects = Partial<Record<"SALESFORCE" | "MOMENTUM", string[]>>;

type TFilterFormula = {
  filterFormula: string;
  filterLogic?: never;
  filters?: never;
};

type TFilterLogic = {
  filterFormula?: never;
  filterLogic: string;
  filters: TFlowFilter[];
};

export type TSalesforceFlowCondition =
  | TFilterFormula
  | TFilterLogic
  | (Partial<TFilterLogic> & Partial<TFilterFormula>);

export type TStep = {
  name?: string;
  description?: string;
  type: TActionTypesEnum;
  args: Record<string, any>;
  ref?: string;
  condition?: {
    type: "run" | "skip";
    predicate: string;
  };
  slaSchedule?: TSLASchedule;
  footerOptions?: TFooterOptions;
  continueOnHalted?: boolean;
  routes?: string[];
};

export type TSLASchedule = {
  slaMinutes: number;
  notifications: TNotification[];
};

export type TNotification = {
  strategy: string;
  reminderMinutes: number;
  receiver: {
    type: string;
    slackUserId?: string;
    slackChannelId?: string;
  };
};

export type TFooterOptions = {
  showCreator?: boolean;
  showSLAMessage?: boolean;
  allowAcknowledgeSLA?: boolean;
  showSourceLink?: boolean;
  dividerType?: string;
  showExecutionId?: boolean;
  showLastSent?: boolean;
  showLiveMode?: boolean;
};

export function renderRecordSteps(steps: Record<string, TStep> | TStep[]): Record<string, TStep> {
  if (!Array.isArray(steps)) {
    console.error("This is an unexpected format of steps");
  }
  return steps as Record<string, TStep>;
}
