import { createAuthGuard } from "@auth0/auth0-vue";

export function createAdminRoutes(app) {
  const authGuard = createAuthGuard(app);
  return [
    // Autopilot extractions
    {
      path: "/admin/autopilot-extractions",
      name: "autopilot-extractions",
      component: () => import("../pages/AutopilotExtractions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Autopilot Extractions",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-extractions/create",
      name: "autopilot-extraction-create",
      component: () => import("../pages/AutopilotExtractionCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Autopilot Extraction",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-extractions/edit/:id",
      name: "autopilot-extraction-edit",
      component: () => import("../pages/AutopilotExtractionEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Autopilot Extraction",
        layout: "AdminDashboard",
      },
    },
    // AI Signals
    {
      path: "/admin/ai-signals",
      name: "ai-signals",
      component: () => import("../pages/AISignalsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Signals",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-signals/create",
      name: "ai-signals-create",
      component: () => import("../pages/AISignalsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Signals",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-signals/edit/:id",
      name: "ai-signals-edit",
      component: () => import("../pages/AISignalsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit AI Signals",
        layout: "AdminDashboard",
      },
    },
    //AI Playground
    {
      path: "/admin/ai-playground",
      name: "ai-playground",
      component: () => import("../pages/AINotificationsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Playground",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-playground/create",
      name: "ai-playground-create",
      component: () => import("../pages/AINotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Workflow Create",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-playground/edit/:id",
      name: "ai-playground-edit",
      component: () => import("../pages/AINotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Playground Edit",
        layout: "AdminDashboard",
      },
    },
    //AI Call Automation
    {
      path: "/admin/contact-automations",
      name: "contact-automations",
      component: () => import("../pages/ContactAutomationsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Call Automations",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/contact-automations/create",
      name: "contact-automations-create",
      component: () => import("../pages/ContactAutomationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Call Automations Create",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/contact-automations/edit/:id",
      name: "contact-automations-edit",
      component: () => import("../pages/ContactAutomationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Automations Edit",
        layout: "AdminDashboard",
      },
    },
    //AI Call Audit
    {
      path: "/admin/call-audit",
      name: "call-audit",
      component: () => import("../pages/AICallAudit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Call Audit",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/exec-briefs",
      name: "exec-briefs",
      component: () => import("../pages/ExecBriefWorkflows.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Executive Brief Workflows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/exec-briefs/edit/:id",
      name: "exec-briefs-edit",
      component: () => import("../pages/ExecBriefsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Executive Brief Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/exec-briefs/create",
      name: "exec-briefs-create",
      component: () => import("../pages/ExecBriefsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Executive Brief Workflow",
        layout: "AdminDashboard",
      },
    },
    //scheduled notifications
    {
      path: "/admin/scheduled-notifications",
      name: "scheduled-notifications",
      component: () => import("../pages/ScheduledNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-notifications/create",
      name: "scheduled-notification-create",
      component: () => import("../pages/ScheduledNotificationCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-notifications/edit/:id",
      name: "scheduled-notification-edit",
      component: () => import("../pages/ScheduledNotificationEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    //scheduled reports
    {
      path: "/admin/scheduled-reports",
      name: "scheduled-reports",
      component: () => import("../pages/ScheduledReportsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Scheduled Reports",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-reports/create",
      name: "scheduled-reports-create",
      component: () => import("../pages/ScheduledReportsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Scheduled Report",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-reports/edit/:id",
      name: "scheduled-reports-edit",
      component: () => import("../pages/ScheduledReportsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Scheduled Report",
        layout: "AdminDashboard",
      },
    },
    //Email Follow-Up Config
    {
      path: "/admin/email-follow-up-config",
      name: "admin-email-follow-up-config",
      component: () => import("../pages/EmailFollowUpHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up",
        layout: "AdminDashboard",
      },
    },
    // slack app home settings
    {
      path: "/admin/slack-home-screen/configuration",
      name: "slack-home-screen-configuration",
      component: () => import("../pages/settings/SlackHomeSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    //notification flows
    {
      path: "/admin/notifications/flows",
      name: "flow-notifications",
      component: () => import("../pages/FlowNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/flows/create",
      name: "flow-notifications-create",
      component: () => import("../pages/FlowNotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/flows/edit/:id",
      name: "flow-notifications-edit",
      component: () => import("../pages/FlowNotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },

    //ai summary notifications
    {
      path: "/admin/notifications/ai-summary",
      name: "ai-summary-notifications",
      component: () => import("../pages/AISummaryNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Summary Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/ai-summary/create",
      name: "ai-summary-notifications-create",
      component: () => import("../pages/AISummaryNotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create AI Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/ai-summary/edit/:id",
      name: "ai-summary-notifications-edit",
      component: () => import("../pages/AISummaryNotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit AI Workflow",
        layout: "AdminDashboard",
      },
    },

    //gifs
    {
      path: "/admin/notifications/gif/home",
      name: "gif-home",
      component: () => import("../pages/GifHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Gif Home",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/edit/:id",
      name: "gif-edit",
      component: () => import("../pages/GifEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Gif",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/search",
      name: "gif-search",
      component: () => import("../pages/GifSearch.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search Gifs",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/add/:id",
      name: "gif-add",
      component: () => import("../pages/GifAdd.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Add Gif",
        layout: "AdminDashboard",
      },
    },

    //workflows
    {
      path: "/admin/workflows",
      name: "workflows-home",
      component: () => import("../pages/WorkflowHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Workflows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/workflows/edit/:id",
      name: "workflows-edit",
      component: () => import("../pages/WorkflowEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/workflows/create/advanced",
      name: "workflows-create-advanced",
      component: () => import("../pages/WorkflowAdvanced.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Workflow",
        layout: "AdminDashboard",
      },
    },

    //Analytics Dashboard
    {
      path: "/admin/analytics",
      name: "analytics-home",
      component: () => import("../pages/AnalyticsHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Usage Analytics",
        layout: "AdminDashboard",
      },
    },

    //execution pipeline
    {
      path: "/admin/execution-pipeline",
      name: "pipeline",
      component: () => import("../pages/ExecutionPipeline.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Execution Logs",
        layout: "AdminDashboard",
      },
    },

    {
      path: "/admin/integrations-home",
      name: "integrations-home",
      component: () => import("../pages/IntegrationsHome.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    {
      path: "/integration-authorize",
      name: "integration-authorize",
      component: () => import("../pages/IntegrationAuthorize.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "UserLogin",
      },
    },

    {
      path: "/admin/team",
      name: "team",
      component: () => import("../pages/TeamHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Team",
        layout: "AdminDashboard",
      },
    },

    //library of recipes
    {
      path: "/admin/recipes",
      name: "recipes",
      component: () => import("../pages/RecipesHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Recipes",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/recipes/:id",
      name: "recipes-preview",
      component: () => import("../pages/RecipesPreview.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Recipe",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/recipes/edit/:id",
      name: "recipes-edit",
      component: () => import("../pages/RecipesEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Use Recipe",
        layout: "AdminDashboard",
      },
    },

    {
      path: "/admin/non-editor",
      name: "non-editor",
      component: () => import("../pages/NonEditor.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    {
      path: "/admin/user-pool-configuration",
      name: "user-pool-configuration",
      component: () => import("../pages/UserPools.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "User Pools",
        layout: "AdminDashboard",
      },
    },
    // Settings - Integrations - Zoom
    {
      path: "/admin/settings/integrations/zoom",
      name: "admin-settings-integrations-zoom",
      component: () => import("../pages/SettingsIntegrationsZoom.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Integrations - Zoom",
        layout: "AdminDashboard",
      },
    },
    //Admin Settings

    //dealrooms
    {
      path: "/admin/settings/dealrooms",
      name: "admin-settings-dealrooms",
      component: () => import("../pages/settings/DealRoomSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Deal Rooms",
        layout: "AdminDashboard",
      },
    },

    //rooms
    {
      path: "/admin/settings/rooms/created",
      name: "admin-settings-rooms",
      component: () => import("../pages/settings/RoomsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Rooms",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/settings/rooms/edit/:id",
      name: "admin-settings-rooms-edit",
      component: () => import("../pages/settings/RoomEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Deal Room Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/settings/rooms/create",
      name: "admin-settings-rooms-create",
      component: () => import("../pages/settings/RoomCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Deal Room Workflow",
        layout: "AdminDashboard",
      },
    },

    //account room settings
    {
      path: "/admin/settings/accounts",
      name: "admin-settings-accounts",
      component: () => import("../pages/settings/AccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Accounts",
        layout: "AdminDashboard",
      },
    },

    //security
    {
      path: "/admin/settings/security",
      name: "admin-settings-security",
      component: () => import("../pages/settings/SecuritySettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Security",
        layout: "AdminDashboard",
      },
    },

    //playbook
    {
      path: "/admin/settings/playbook",
      name: "admin-settings-playbook",
      component: () => import("../pages/settings/PlaybookHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Playbook",
        layout: "AdminDashboard",
      },
    },

    //salesforce
    {
      path: "/admin/settings/salesforce",
      name: "admin-settings-salesforce",
      component: () => import("../pages/settings/SalesforceSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Salesforce",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/salesforce-settings",
      name: "admin-settings-salesforce",
      component: () => import("../pages/settings/SalesforceSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Salesforce Settings",
        layout: "AdminDashboard",
      },
    },

    //google drive
    {
      path: "/admin/settings/google-drive",
      name: "admin-settings-google-drive",
      component: () => import("../pages/settings/GoogleDriveAccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Google Drive",
        layout: "AdminDashboard",
      },
    },

    //zoom
    {
      path: "/admin/settings/zoom",
      name: "admin-settings-zoom",
      component: () => import("../pages/settings/ZoomAccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Zoom",
        layout: "AdminDashboard",
      },
    },

    //ai settings
    {
      path: "/admin/momentum-ai-settings",
      name: "admin-settings-momentum-ai",
      component: () => import("../pages/settings/MomentumAISettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Momentum AI Settings",
        layout: "AdminDashboard",
      },
    },

    // demo pages
    {
      path: "/admin/demo/draggable",
      name: "demo-draggable",
      component: () => import("../pages/demo/CDraggable.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Draggable",
      },
    },
    {
      path: "/admin/demo/icons",
      name: "demo-icons",
      component: () => import("../pages/demo/AppIcons.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Icons",
      },
    },
    {
      path: "/admin/demo/field-paths",
      name: "demo-field-paths",
      component: () => import("../pages/demo/FieldPaths.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Field Paths",
      },
    },
    {
      path: "/admin/demo/condition-builder",
      name: "demo-condition-builder",
      component: () => import("../pages/demo/ConditionBuilder.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Condition Builder",
      },
    },
    {
      path: "/admin/demo/select-field-model",
      name: "demo-select-field-model",
      component: () => import("../pages/demo/SelectFieldModal.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Select Field Modal",
      },
    },
    {
      path: "/admin/demo/cron-scheduler",
      name: "demo-cron-scheduler",
      component: () => import("../pages/demo/CronScheduler.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Cron Scheduler",
      },
    },
    {
      path: "/admin/demo/sql-filter-builder",
      name: "demo-sql-filter-builder",
      component: () => import("../pages/demo/SQLFilterBuilder.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "SQL Filter Builder",
      },
    },
    {
      path: "/admin/demo/component-library",
      name: "component-library",
      component: () => import("../pages/demo/ComponentLibrary.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Component Library",
      },
    },
  ];
}
