import { cloneDeep } from "lodash";
export const deal_assist = {
  state: () => ({
    workflows: [],
    workflow: null,
    workflowsFetched: null,
    opFields: null,
    form: null,
    approvers: [],
    selectedFields: [],
    slackUsers: [],
    users: [],
    edit: false,

    success: false,
    error: false,
    errors: [],
    editorErrors: [],
    submittingForm: 1,
    validForm: [],
    workflowSet: false,
  }),

  mutations: {
    setWorkflow(state, payload) {
      const workflow = cloneDeep(payload);
      state.workflow = workflow;
      state.form = workflow;
      state.workflowSet = true;
    },
    setWorkflows(state, workflows) {
      state.workflows = workflows;
      state.workflowsFetched = true;
    },

    //form updates
    updateForm(state, data) {
      state.form = data;
    },
    updateBasicSettings(state, data) {
      state.validForm = state.validForm.map((string) => {
        if (string === "basic_info") {
          string.replace(true);
        }
      });
      const form = state.form;
      form.name = data.name;
      form.description = data.description;

      //NOTE: this is only going to work for now until templates change this side of the object, too.
      if (state.form.category === "default") {
        return;
      }

      form.wizard.dialog.title = data.name;
      form.wizard.dialog.headerMessage = data.description;
    },
    updateElements(state, data) {
      state.form.wizard.dialog.elements = data.elements;
    },
    //for approval_custom template
    updateApprovers(state, data) {
      state.validForm = state.validForm.map((string) => {
        if (string === "approvers") {
          string.replace(true);
        }
      });
      //will need to update approvers or receiver based on the category type
      const wizard = state.form.wizard;

      if (state.form.category === "approval_custom") {
        wizard.approvers = data.approvers;
        wizard.notifyDealRoomChannel = data.notifyDealRoomChannel;
        wizard.notifySalesforce = data.notifySalesforce;
        wizard.notifyPerApproval = data.notifyPerApproval;
        wizard.allowCreateConversation = data.allowCreateConversation;
        wizard.allowStoreConversation = data.allowStoreConversation;
        wizard.allowPrivateChannel = data.allowPrivateChannel;
      }

      if (state.form.category === "simple_task") {
        wizard.receiver = data.receiver;
        wizard.notifyDealRoomChannel = data.notifyDealRoomChannel;
        wizard.allowCreateConversation = data.allowCreateConversation;
        wizard.allowStoreConversation = data.allowStoreConversation;
        wizard.allowPrivateChannel = data.allowPrivateChannel;
      }
    },
    updateOpportunities(state, data) {
      state.form.wizard.dialog.groupFields = data;
    },
    //form updates
    setSuccess(state, status) {
      state.success = status;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setApprovers(state, approvers) {
      state.approvers = approvers;
    },
    setSelectedFields(state, fields) {
      state.selectedFields = fields;
    },
    reset(state) {
      state.form = null;
      state.approvers = [];
      state.users = [];
      state.selectedFields = [];
      state.edit = false;
      state.error = false;
      state.validForm = [];
      state.errors = [];
      state.workflowSet = false;
    },
    setEdit(state) {
      state.edit = true;
    },
    submitStatus(state) {
      state.validForm = [];
      state.submittingForm += 1;
    },
    formStatus(state, status) {
      state.validForm.push(status);
    },
    setSlackUsers(state, data) {
      state.slackUsers = data;
    },
    setEditorErrorMarkers(state, data) {
      state.editorErrors = data;
    },
    setOpFields(state, fields) {
      state.opFields = fields;
    },
  },
  actions: {
    //form updates
    setWorkflow({ commit }, data) {
      commit("reset");
      commit("setEdit");
      commit("setWorkflow", data);
    },
    setWorkflows({ commit }, data) {
      commit("setWorkflows", data);
    },
    updateBasicSettings({ commit }, data) {
      commit("updateBasicSettings", data);
    },
    updateElements({ commit }, data) {
      commit("updateElements", data);
    },
    updateApprovers({ commit }, data) {
      commit("updateApprovers", data);
    },
    updateOpportunities({ commit }, data) {
      commit("updateOpportunities", data);
    },
    //form updates

    //promise success and error handling
    setSuccess({ commit }, status) {
      commit("setSuccess", status);
    },
    //promise success and error handling

    setUsers({ commit }, users) {
      commit("setUsers", users);
    },
    setApprovers({ commit }, approvers) {
      commit("setApprovers", approvers);
    },
    setSelectedFields({ commit }, fields) {
      commit("setSelectedFields", fields);
    },
    reset({ commit }) {
      commit("reset");
    },
    setEdit({ commit }) {
      commit("setEdit");
    },
    validateForm({ commit }) {
      commit("submitStatus");
    },
    setValidationStatus({ commit }, status) {
      commit("formStatus", status);
    },
    setSlackUsers({ commit }, data) {
      commit("setSlackUsers", data);
    },
    setEditorErrorMarkers({ commit }, data) {
      commit("setEditorErrorMarkers", data);
    },
    setOpFields({ commit }, fields) {
      commit("setOpFields", fields);
    },
  },
};
